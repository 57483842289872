@use "~ag-grid-community/styles" as ag;
@import "./styles/variables.scss";
@import "./styles/pages/attack-path-viewer.scss";
@import "./styles/pages/bim.scss";
@import "./styles/pages/modal-path-viewer.scss";

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url(./assets/fonts/Proxima-Nova-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  src: local("Montserrat Medium"),
    url(./assets/fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat Thin";
  src: local("Montserrat Thin"),
    url(./assets/fonts/Montserrat-Thin.ttf) format("truetype");
}

@include ag.grid-styles(
  (
    theme: alpine-dark,
    --ag-header-background-color: #101828,
    --ag-odd-row-background-color: #1d2939,
    --ag-row-hover-color: #344054,
  )
);

// WARNING: Theme overrides
html {
  overflow: hidden;
}

#root {
  height: 100vh;
  width: 100%;
}

// Scrollbar customizations

body {
  overflow-x: hidden;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.5);
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 4px;
  outline: "1px solid slategrey";
}

// highlight resize handler of react grid layout
.react-grid-item > .react-resizable-handle::after {
  width: 8px !important;
  height: 8px !important;
  border-right: 2px solid white !important;
  border-bottom: 2px solid white !important;
}

.ag-cell {
  font-size: 12px !important;
}

.ag-cell-wrapper {
  height: 100% !important;
}

.ag-row {
  background-color: #1d2939 !important;
}

.ag-header-row {
  background-color: #101828 !important;
}